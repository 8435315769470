import './application.scss';
import 'boxicons';

setTimeout(() => {
 const notices = document.querySelectorAll('[role="notice"]');
 notices.forEach(notice => notice.remove());
}, 2000);

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// eslint-disable-next-line no-console
// console.info('Vite ⚡️ Rails');

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// eslint-disable-next-line no-console
// console.info(
//   'Visit the guide for more information: ',
//   'https://vite-ruby.netlify.app/guide/rails',
// );

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
